import React from "react";
import Layout from "../../components/Layout";

export default () => (
    <Layout>
        <section className="section">
            <div className="container">
                <div className="content">
                    <h1>Thank you!</h1>
                    <p>Thank you for contacting us. We'll get back to you as soon as possible.</p>
                </div>
            </div>
        </section>
    </Layout>
);
